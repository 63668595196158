/** Dependencies */
import React, { useEffect, useState } from 'react';
import { getItem } from '../services/LocaleStorage';

/** Components */
import Logger from './../components/Logger';
import { ReactComponent as ReactLoader }  from './../assets/img/loader-dots.svg';

/** Redux **/
import { useSelector } from 'react-redux';

/** CSS */
import './FaceBook.scss';

function Instagram( props ) 
{
  const {
    metaSource,
    lockedFiles
  } = props;

  // define local storage name for token
  const localStorageToken = 'fblst_' + process.env.REACT_APP_META_APP_ID;
  const fbAccessToken = getItem( localStorageToken );

  /** Init state **/
  const [loginDatas, setLoginDatas] = useState( null );
  const [managedPages, setManagedPages] = useState( [] );
  const [selectedPages, setSelectedPages] = useState( [] );
  const [isLoading, setIsLoading] = useState( false );
  const [error, setError] = useState( null );
  const [startDate, setStartDate] = useState( null );
  const [endDate, setEndDate] = useState( null );

  /** Get state from redux store **/
  const connectedAccounts = useSelector( state => state.connectedAccounts );

  // defined if extractor is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'extract-meta-ig' );

  /** Execute extract data */
  const executeExtract = () => 
  {
    if( selectedPages.length > 0 )
    {
      // set loading to true 
      setIsLoading( true );

      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          userID: loginDatas.id,
          userToken: fbAccessToken,
          pages: selectedPages,
          startDate: startDate,
          endDate: endDate
        })
      };

      fetch( process.env.REACT_APP_API_URL + '/extract-' + metaSource + 'pageinsights', requestOptions)
        .then (response => response )
        .then( data => {
          setIsLoading( false );
          setSelectedPages( [] );
          setStartDate( null );
          setEndDate( null )
        });   
    }    
  }

  /** Update selected page list */
  const updateSelectedPageList = event => 
  {
    // get page id
    const pageID = event.target.value;

    // get checked
    const checked = event.target.checked;

    if( checked === false )
      setSelectedPages( selectedPages.filter( value => value !== pageID ) );
    else
      setSelectedPages( [...selectedPages, pageID ] );
  }

  // Get pages managed
  useEffect( () => 
  {
    if( loginDatas?.id && fbAccessToken )
    {
      fetch( 'https://graph.facebook.com/' + loginDatas.id + '/accounts?access_token=' + fbAccessToken + '&limit=1000' )
      .then (response => response.json() )
      .then( response => 
        response?.error !== undefined ?
        setError( response.error.message )
        : setManagedPages( response.data.map( data => ({
          access_token: data.access_token,
          id: data.id,
          name: data.name
        })))
      );
    }
  }, [
    connectedAccounts, 
    loginDatas
  ]);

  // init login datas
  useEffect( () => setLoginDatas( JSON.parse( getItem( 'fbUserDatas' ) ) ), [] );

  return (
    <div className="facebook">
      <h2>Meta Instagram</h2>

      { connectedAccounts.value.includes( 'meta' ) && !isLocked ?
        <React.Fragment>
          {/* Pages managed */}
          { managedPages.length > 0 ?
            <div className='managed_pages'>
              <ul>
                { managedPages.map( ( page, index ) => 
                    <li key={index}>
                      <input 
                        checked={selectedPages.includes( page.id + '|' + page.name )}
                        onChange={updateSelectedPageList} 
                        id={'managed_page_id_' + page.id} 
                        value={page.id + '|' + page.name}
                        type='checkbox' 
                      />
                      <label htmlFor={ 'managed_page_id_' + page.id}>{page.name}</label>
                    </li>
                  )            
                }
              </ul>
            </div>
            : "Aucune page disponible"
          }

          {/* Fields */}
          <div className="extract-fields">
            <form>
              <div>
                <label>Date de début : </label>
                <input 
                  value={startDate === null ? '' : startDate} 
                  onChange={ e => setStartDate( e.target.value )} 
                  type="date" 
                  name="start-date"
                />
              </div>
              <div>
                <label>Date de fin : </label>
                <input 
                  value={endDate === null ? '' : endDate} 
                  onChange={ e => setEndDate( e.target.value )} 
                  type="date" 
                  name="end-date" 
                />
              </div>
            </form>
          </div>

          {/* Execute */}
          <div className='execution'>
            <button
              disabled={
                loginDatas === null
                || isLoading 
                || selectedPages.length === 0
              }
              onClick={executeExtract}
            >Exécuter l'extraction des données</button>
            {isLoading ? <ReactLoader /> : false }
          </div>
        </React.Fragment>
        : isLocked ? 
          <p>
            Extraction en cours : { lockedFiles['extract-meta-ig'].pages.join( ' / ' )}
            <br />
            {lockedFiles['extract-meta-ig'].startDate !== null ? 
              'Du ' 
              + lockedFiles['extract-meta-ig'].startDate.split( '-' )[2] + '/' 
              + lockedFiles['extract-meta-ig'].startDate.split( '-' )[1] + '/' 
              + lockedFiles['extract-meta-ig'].startDate.split( '-' )[0]
              : ''
            }
            <br />
            {lockedFiles['extract-meta-ig'].endDate !== null ? 
              'Au ' 
              + lockedFiles['extract-meta-ig'].endDate.split( '-' )[2] + '/' 
              + lockedFiles['extract-meta-ig'].endDate.split( '-' )[1] + '/' 
              + lockedFiles['extract-meta-ig'].endDate.split( '-' )[0]
              : ''
            }
          </p>
          : <p>Vous devez vous connecter à Facebook</p>
      }

      {/* Log */}
      <Logger serviceName={ 'meta-' + metaSource + '-posts' } />

      {/* Error */}
      { error === false ?
        <div className='error'>{error}</div>
        : false
      }
      
    </div>
  );
}

export default Instagram; 