/** Dependencies */
import React, { useEffect, useState } from 'react';

/** Components */
import Logger from './../components/Logger';
import { ReactComponent as ReactLoader }  from './../assets/img/loader-dots.svg';

/** Helpers */
import { resetProcess } from '../helpers/functions';

/** CSS */
import './Pages.scss';

function Matomo( props )
{
  const {
    lockedFiles
  } = props;

  /** Init States */
  const [url, setUrl] = useState( '' );
  const [siteID, setSiteID] = useState( '' );
  const [startDate, setStartDate] = useState( '' );
  const [endDate, setEndDate] = useState( '' );
  const [token, setToken] = useState( '' );
  const [isLoading, setIsLoading] = useState( false );
  const [error, setError] = useState( null );

  // defined if extractor is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'extract-matomo' );

  /** Execute extract */
  const executeExtract = () => 
  {
    // set loading to true 
    setIsLoading( true );

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        apiUrl: url,
        siteID: siteID,
        userToken: token,
        startDate: startDate,
        endDate: endDate
      })
    };

    fetch( process.env.REACT_APP_API_URL + '/extract-matomoinsights', requestOptions)
      .then (response => response.json() )
      .then( data => 
      {
        if( data?.error === undefined )
        {
          setIsLoading( false );
          setUrl( '' );
          setSiteID( '' );
          setStartDate( '' );
          setEndDate( '' );
          setToken( '' )
        }
      });   
  }

  /** Load locked file list */
  useEffect( () => 
  {
    if( lockedFiles['extract-matomo']?.error !== undefined  ) 
      setError( lockedFiles['extract-matomo'].error );
    
  }, [lockedFiles]);

  return (
    <div className="facebook">
      <h2>Matomo</h2>
      
      {!isLocked ?
        <React.Fragment>
          {/* Fields */}
          <div className="extract-fields">
            <form>
              <div>
                <label>URL : </label>
                <input onChange={ e => setUrl( e.target.value )} type="text" name="url" value={url} />
              </div>
              <div>
                <label>Site ID : </label>
                <input onChange={ e => setSiteID( e.target.value )} type="text" name="siteID" value={siteID} />
              </div>
              <div>
                <label>Token : </label>
                <input onChange={ e => setToken( e.target.value )} type="text" name="token" value={token} />
              </div>
              <div>
                <label>Date de début : </label>
                <input onChange={ e => setStartDate( e.target.value )} type="date" name="start-date" value={startDate} />
              </div>
              <div>
                <label>Date de fin : </label>
                <input onChange={ e => setEndDate( e.target.value )} type="date" name="end-date" value={endDate} />
              </div>
            </form>
          </div>

          {/* Execute */}
          <div className='execution'>
            <button
              disabled={
                startDate.toString().trim() === ''
                || endDate.toString().trim() === ''
                || siteID.toString().trim() === ''
                || token.toString().trim() === ''
                || url.toString().trim() === ''
                || isLoading 
                || isLocked
              }
              onClick={executeExtract}
            >Exécuter l'extraction des données</button>
            {isLoading ? <ReactLoader /> : false }            
          </div>
          
        </React.Fragment>
      : error === null && lockedFiles['extract-matomo']?.apiUrl !== undefined ?
        <p>
          Extraction en cours pour l'url : { lockedFiles['extract-matomo'].apiUrl + ' - ID site : ' + lockedFiles['extract-matomo'].siteID }
          <br />
          {lockedFiles['extract-matomo'].startDate !== null ? 
            'Du ' 
            + lockedFiles['extract-matomo'].startDate.split( '-' )[2] + '/' 
            + lockedFiles['extract-matomo'].startDate.split( '-' )[1] + '/' 
            + lockedFiles['extract-matomo'].startDate.split( '-' )[0]
            : ''
          }
          <br />
          {lockedFiles['extract-matomo'].endDate !== null ? 
            'Au ' 
            + lockedFiles['extract-matomo'].endDate.split( '-' )[2] + '/' 
            + lockedFiles['extract-matomo'].endDate.split( '-' )[1] + '/' 
            + lockedFiles['extract-matomo'].endDate.split( '-' )[0]
            : ''
          }
        </p>
      : 
        <div>
          <div className='error'>{error}</div>
          <button className='reset'
            onClick={ () => {
              resetProcess( 'extract-matomo' );
              setError( null );
              setIsLoading( false );
            }} 
            width="10"
          >Reset</button>
        </div>
      }

      {/* Log */}
      <Logger serviceName={ 'matomo' } />

    </div>
  );
}

export default Matomo; 