/** Dependencies */
import { configureStore } from '@reduxjs/toolkit';

/** Reducers */
import eventSourcesReducer from './reducers/connectedAccounts';

const store = configureStore({
	reducer: {
		connectedAccounts: eventSourcesReducer
	}
});

export default store;