/** Dependencies */
import React, { useState, useEffect } from 'react';
import { getItem } from "./../services/LocaleStorage";

/** Components */
import Logger from './../components/Logger';
import { ReactComponent as ReactLoader }  from './../assets/img/loader-dots.svg';

/** Redux **/
import { useSelector } from 'react-redux';

/** Helpers */
import { resetProcess } from '../helpers/functions';

/** CSS */
import './GoogleGSC.scss';

function GoogleGSC( props ) 
{
  const {
    lockedFiles
  } = props;

  /** Init state **/
  const [domain, setDomain] = useState( null );
  const [startDate, setStartDate] = useState( null );
  const [endDate, setEndDate] = useState( null );
  const [isLoading, setIsLoading] = useState( false );
  const [error, setError] = useState( null );

  /** Get state from redux store **/
  const connectedAccounts = useSelector( state => state.connectedAccounts );

  // defined if extractor is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'extract-google-gsc' );
  
  /** Execute extract */
  const executeExtract = () => 
  {
    // set loading to true 
    setIsLoading( true );

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        domain: domain,                
        startDate: startDate,
        endDate: endDate,
        accessToken: getItem( 'googleAccessToken' )
      })
    };

    fetch( process.env.REACT_APP_API_URL + '/extract-googlegscinsights', requestOptions)
      .then (response => response )
      .then( data => 
      {
        if( data?.error === undefined )
        {
          setIsLoading( false );
          setDomain( null );        
          setStartDate( null );
          setEndDate( null );
        }
      });   
  }

  /** Load locked files */
  useEffect( () => 
  {
    if( lockedFiles['extract-google-gsc']?.error !== undefined  ) 
      setError( lockedFiles['extract-google-gsc'].error );
    
  }, [lockedFiles]);

  return (
    <div className='google-gsc content-wrapper'>
      <h2>Google Search Console</h2>

      { connectedAccounts.value.includes( 'google' ) && !isLocked ?
        <React.Fragment>
          {/* Fields */}
          <div className="extract-fields">
            <form>
              <div>
                <label>Domain : </label><input onChange={ e => setDomain( e.target.value )} type="text" name="domain" />
              </div>
              <div>
                <label>Date de début : </label><input onChange={ e => setStartDate( e.target.value )} type="date" name="start-date" />
              </div>
              <div>
                <label>Date de fin : </label><input onChange={ e => setEndDate( e.target.value )} type="date" name="end-date" />
              </div>
            </form>
          </div>

          {/* Execute */}
          <div className='execution'>
            <button
              disabled={
                startDate === null
                || startDate.trim() === ''
                || endDate === null
                || endDate.trim() === ''
                || domain === null
                || domain.trim() === ''
                || isLoading 
                || isLocked
              }
              onClick={executeExtract}
            >Exécuter l'extraction des données</button>
            {isLoading ? <ReactLoader /> : false }
          </div>

        </React.Fragment>
      : error === null && lockedFiles['extract-google-gsc']?.domain !== undefined ? 
        <p>
          Extraction en cours du domaine : { lockedFiles['extract-google-gsc'].domain }
          <br />
          {lockedFiles['extract-google-gsc'].startDate !== null ? 
            'Du ' 
            + lockedFiles['extract-google-gsc'].startDate.split( '-' )[2] + '/' 
            + lockedFiles['extract-google-gsc'].startDate.split( '-' )[1] + '/' 
            + lockedFiles['extract-google-gsc'].startDate.split( '-' )[0]
            : ''
          }
          <br />
          {lockedFiles['extract-google-gsc'].endDate !== null ? 
            'Au ' 
            + lockedFiles['extract-google-gsc'].endDate.split( '-' )[2] + '/' 
            + lockedFiles['extract-google-gsc'].endDate.split( '-' )[1] + '/' 
            + lockedFiles['extract-google-gsc'].endDate.split( '-' )[0]
            : ''
          }
        </p>
      : error !== null && isLocked ?
        <div>
          <div className='error'>{error}</div>
          <button className='reset'
            onClick={ () => {
              resetProcess( 'extract-google-gsc' );
              setError( null );
              setIsLoading( false );
            }} 
            width="10"
          >Reset</button>
        </div>
      :
        <p>Vous devez vous connecter à Google</p>
      }

      {/* Log */}
      <Logger serviceName={ 'google-gsc' } closeEvent={''} />

    </div>
  );
}

export default GoogleGSC; 