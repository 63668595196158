/** Dependencies */
import React, { useEffect, useState } from 'react';

/** Components */
import { ReactComponent as ReactLoader }  from './../../assets/img/loader-dots.svg';

/** Helpers */
import { resetProcess } from './../../helpers/functions';

/** CSS */
import './index.scss';

function FileUploader( props ) 
{
  const {
    fetchURL,
    params,
    lockedFileDatas
  } = props;

  const [selectedFile, setSelectedFile] = useState( null );
  const [isLoading, setIsLoading] = useState( false );
  const [error, setError] = useState( null );

  const changeHandler = event => {
    setSelectedFile( event.target.files[0] );
  };
  
  const handleSubmission = () => 
  {
    setIsLoading( true );
    
    const formData = new FormData();

    // append updaloed file to form data
    formData.append( 'uploaded_file', selectedFile );
    formData.append( 'uploaded_params', JSON.stringify( params ) )

    fetch(
      fetchURL,
      {
        method: 'POST',
        body: formData,
      }
    )
    .then( response => response.json() )
    .then( result => 
    {
      if( result?.error === undefined )
      {
        setError( null );
        setIsLoading( false );
        setSelectedFile( null );
      }
    })
    .catch( error => 
    {
      setError( error );
      setIsLoading( false );
      setSelectedFile( null );
    });
  };

  /** Load locked files */
  useEffect( () => 
  {
    if( lockedFileDatas?.error !== undefined  ) 
      setError( lockedFileDatas.error );
    
  }, [lockedFileDatas]);

  return (
    <div className='file-uploader'>
      {
        error === null && lockedFileDatas?.fileName === undefined ? 
          <React.Fragment>
            <input type="file" name="file" onChange={changeHandler} />
            <div className='execution'>
              <button 
                disabled={!selectedFile || isLoading} 
                onClick={handleSubmission}
              >Exécuter l'extraction des données</button>
              {isLoading ? <ReactLoader /> : false }
            </div>
          </React.Fragment>
        : error === null && lockedFileDatas?.fileName !== undefined ?
          <p>Extraction en cours du fichier : { lockedFileDatas.fileName }</p>  
        : error !== null ?
          <div>
            <div className='error'>{error}</div>
            <button className='reset'
              onClick={ () => {
                resetProcess( 'extract-google-psi' );
                setError( null );
                setIsLoading( false );
                setSelectedFile( null );
              }} 
              width="10"
            >Reset</button>
          </div>
        : null
      }
    </div>
  );
}

export default FileUploader; 