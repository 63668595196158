/** Dependencies */
import { createSlice } from '@reduxjs/toolkit'

export const connectedAccountsSlice = createSlice({
  name: 'connectedAccounts',
  initialState: {
    value: []
  },
  reducers: {
    addConnectedAccounts: ( state, action ) => {
      state.value = [...state.value, action.payload];
    },
    removeConnectedAccounts: ( state, action ) => {
      state.value = state.value.filter( value => value !== action.payload );
    }
  }
})

export const { addConnectedAccounts, removeConnectedAccounts } = connectedAccountsSlice.actions

export default connectedAccountsSlice.reducer