/** Dependencies */
import React, { useEffect, useState } from 'react';

/** CSS */
import './index.scss';

function Logger( props )
{
  /** Init props */
  const {
    serviceName
  } = props;

  /** Init status **/
  const [message, setMessage] = useState( '' );
  
  useEffect( () => 
  {
    // instance of Event Source
    const evtSource = new EventSource( process.env.REACT_APP_API_URL + '/getLogs.php?filename=extract-' + serviceName );
  
    // update message
    evtSource.onmessage = e => setMessage( e.data );

    return () => evtSource.close();

  }, []);

  return (
    <div className="logs-wrapper">
      <ul>
        { message.trim() !== '' ?
          JSON.parse( message ).map( ( line, index ) => <li key={index}>{line}</li>  )
          : <li>Le fichier de logs est vide.</li>
        }
      </ul>
    </div>
  );
}

export default Logger;