/** Dependencies */
import React from 'react';
import { getItem } from "./../services/LocaleStorage";

/** Components */
import FileUploader from '../components/FileUploader';
import Logger from './../components/Logger';

/** Redux **/
import { useSelector } from 'react-redux';

/** CSS */
import './GooglePSI.scss';

function GooglePSI( props ) 
{
  const {
    lockedFiles
  } = props;

  /** Get state from redux store **/
  const connectedAccounts = useSelector( state => state.connectedAccounts );

  // defined if extractor is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'extract-google-psi' );

  return (
    <div className='google-psi content-wrapper'>
      <h2>Google Page Speed Insights</h2>

      {/* File Uploader */}
      { connectedAccounts.value.includes( 'google' ) ? 
          <FileUploader 
            fetchURL = {process.env.REACT_APP_API_URL + '/extract-googlepsiinsights'} 
            params = {{
              accessToken: getItem( 'googleAccessToken' )
            }}
            lockedFileDatas = { lockedFiles['extract-google-psi'] }
          />
        : 
          <p>Vous devez vous connecter à Google</p>
      }

      {/* Log */}
      <Logger serviceName={ 'google-psi' } />

    </div>
  );
}

export default GooglePSI; 