/** Dependencies */
import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { setItem, getItem, removeItem } from "./../../services/LocaleStorage";

/** Components */
import { ReactComponent as ReactOvalLoader }  from './../../assets/img/loader-oval.svg';

/** Redux **/
import { useDispatch } from 'react-redux';
import { addConnectedAccounts, removeConnectedAccounts } from './../../reducers/connectedAccounts';

/** Images */
import GoogleLogo from './../../assets/img/logoGoogle.png';
import Login from './../../assets/img/login.svg';
import Logout from './../../assets/img/logout.svg';

/** CSS */
import './index.scss';

const GoogleAuthButton = ( props ) => 
{
  const {
    scope
  } = props;

  /** Init state **/
  const [googleAccessToken, setGoogleAccessToken] = useState( null );
  const [userDatas, setUserDatas] = useState( null );
  const [isLoading, setIsLoading] = useState( false );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  /** Login with useGoogleLogin component */
  const login = useGoogleLogin(
  {
    flow: 'auth-code',
    scope: scope,
    onSuccess: tokenResponse => 
    {
      // set loadgin to true
      setIsLoading( true );

      fetch( process.env.REACT_APP_API_URL + '/get-access-token', {
        method: 'POST',
        body: JSON.stringify({ 
          code: tokenResponse.code,
          redirectUrl: document.location.origin
        })
      })
      .then (response => response.json() )
      .then( response => 
      {
        setIsLoading( true );
        setGoogleAccessToken( JSON.stringify( response ) );
        dispatch( addConnectedAccounts( 'google' ) );
      })
    },
    onError: errorResponse => console.log( errorResponse ),
  });

  /** Logout and reset values */
  const logout = () => 
  {
    removeItem( 'googleAccessToken' );    
    setGoogleAccessToken( null );
    setUserDatas( null );
    dispatch( removeConnectedAccounts( 'google' ) );
  }

  /** Return bolean if access token is expired or not */
  const isTokenExpired = () =>
  {
    if( getItem( 'googleAccessToken' ) !== null )
    {
      // get google access token storage
      const tokenStorage = JSON.parse( getItem( 'googleAccessToken' ) );
  
      // get created date
      const createdDateTime = tokenStorage.created;
  
      // get expired in datetime
      const expiredInDateTime = tokenStorage.expires_in;
  
      // get current datetime
      const currentDateTime = Math.floor( Date.now() / 1000 );
  
      // get expired date time
      const expiredDateTime = createdDateTime + expiredInDateTime - 600;
  
      if( expiredDateTime < currentDateTime )
        return true;
      else
        return false;
      
    } else
      return false;
  }

  /** Refresh access token */
  const refreshAccessToken = () => 
  {
    if( getItem( 'googleAccessToken' ) !== null )
    {
      // get google access token storage
      const tokenStorage = JSON.parse( getItem( 'googleAccessToken' ) );

      // set loading to true
      setIsLoading( true );

      fetch( process.env.REACT_APP_API_URL + '/get-access-token', {
        method: 'POST',
        body: JSON.stringify({ 
          refreshToken: tokenStorage.refresh_token,
          redirectUrl: document.location.origin
        })
      })
      .then (response => response.json() )
      .then( response => 
      {
        setIsLoading( false );
        setGoogleAccessToken( JSON.stringify( response ) );
        dispatch( addConnectedAccounts( 'google' ) );
      })
    }
  }

  /** Update local storage if access token changed */
  useEffect( () => 
  {
    if( googleAccessToken !== null )
    {
      // set google access token if upated
      setItem( 
        'googleAccessToken', 
        googleAccessToken
      );

      // set loading to true
      setIsLoading( true );
      
      // load user infos
      fetch( 'https://www.googleapis.com/oauth2/v1/userinfo?access_token=' + JSON.parse( googleAccessToken ).access_token )
      .then (response => response.json() )
      .then( response => 
      {
        setIsLoading( false );
        setUserDatas( response ) 
      });

      // set interval to check if access token is expired every others 10 minutes
      const interval = setInterval(() =>
      {
        if( isTokenExpired() )
          refreshAccessToken();

      }, 600000);
      return () => clearInterval( interval );
    }
    
  }, [ googleAccessToken ]);

  /** Set current access token if local stored */
  useEffect( () => 
  {
    let googleAccessToken = null;
    if( getItem( 'googleAccessToken' ) !== null );
      googleAccessToken = JSON.parse( getItem( 'googleAccessToken' ) );

    if(
      googleAccessToken !== null 
      && googleAccessToken?.error === undefined
    ){
      if( !isTokenExpired() )
      {
        setGoogleAccessToken( getItem( 'googleAccessToken' ) );
        dispatch( addConnectedAccounts( 'google' ) );
      } else
        refreshAccessToken();
    } else
      logout();

  }, []);

  return (
    <React.Fragment>
      { userDatas === null || userDatas?.error !== undefined ?
        <div className='content'>
          <div className={ isLoading === true ? 'loader' : 'loader hide' }>
            <ReactOvalLoader />
          </div>
          <img 
            src={ GoogleLogo }
            width="50"
            alt="Google logo"
          />
          <img onClick={() => login()} src={Login} width="50" className='login' alt="Login button" />
        </div>
      : 
        <div className='content'>
          <div className={ isLoading === true ? 'loader' : 'loader hide' }>
            <ReactOvalLoader />
          </div>
          <img 
            src={ GoogleLogo }
            width="50"
            alt="Google logo"
          />
          <img 
            src={ userDatas.picture } 
            width="50"
            title={ userDatas.name + ' - ' + userDatas.email }
            alt="User profil"
          />
          <img onClick={() => logout()} src={Logout} width="50" className='logout' alt="Logout button" />
        </div>
      }
    </React.Fragment>
  );
}

export default GoogleAuthButton;