/**
 * Sort object keys order by pattern array values
 * @param {object} object 
 * @param {array} pattern 
 * @returns object
 */
export const orderObjectKeysWithPattern = ( object, pattern ) => 
{
  let result = {};
  pattern.forEach( key => {
    if( object[key] )
      result[key] = object[key]
  });

  return result;
}

/**
 * Remove locked file to reset process
 * @param {string} serviceName 
 */
export const resetProcess = ( serviceName ) => 
{
  // Simple POST request with a JSON body using fetch
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ 
      serviceName: serviceName,
    })
  };

  fetch( process.env.REACT_APP_API_URL + '/remove-locked-file', requestOptions )
  .then( response => response.json() )
  .then( response => 
  {
    console.log( response );
  });
}