/** Dependencies **/
import React, { cloneElement, useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";

/** Components */
import { ReactComponent as ReactOvalLoader }  from './../../assets/img/loader-oval.svg';
import GoogleAuthButton from './../../components/GoogleAuthButton';  
import FBAuthButton from './../../components/FBAuthButton';

/** CSS */
import 'normalize.css';
import './index.scss';

/** Images */
import KairnLogo from './../../assets/img/logoKairn.svg';
import ProgresScoreLogo from './../../assets/img/progresScore.svg';

function App( props ) 
{
  const {
    page
  } = props;

  /** Init status **/
  const [lockedFiles, setLockedFiles] = useState( {} );

  useEffect( () => 
  {
    // instance of Event Source
    const evtSource = new EventSource( process.env.REACT_APP_API_URL + '/getLockedFiles.php' );
  
    // update message
    evtSource.onmessage = e => setLockedFiles( JSON.parse( e.data ) );

    return () => evtSource.close();

  }, []);

  return (
    <React.Fragment>
      <header>
        <div className='header-content'>
          <div className='titles'>
            <h1><img src={KairnLogo} width={100} alt="Kairn logo" /></h1>
            <h2><img src={ProgresScoreLogo} width={100} alt="Progres Score logo" /></h2>
          </div>
          <div className='authentications'>
            <div className='google-auth'>
              <GoogleAuthButton
                scope='
                  https://www.googleapis.com/auth/webmasters 
                  https://www.googleapis.com/auth/spreadsheets 
                  https://www.googleapis.com/auth/drive
                '
              />
            </div>
            <div className='separator'>&nbsp;</div>
            <div className='fb-auth'>
              <FBAuthButton 
                appId={process.env.REACT_APP_META_APP_ID}
                scope='
                  email,
                  read_insights,
                  pages_show_list,
                  ads_read,
                  instagram_basic,
                  instagram_manage_insights,
                  pages_read_engagement,
                  public_profile,
                  business_management
                '
              />
            </div>
          </div>
        </div>
        <nav>
          <div className='categories'>
            <h3>Extract</h3>
            <ul>
              <li>
                <Link className={window.location.pathname.includes( 'facebook' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/facebook" }>
                  FaceBook
                  { Object.keys( lockedFiles ).includes( 'extract-meta-fb' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>                
              </li>
              <li>
                <Link className={window.location.pathname.includes( 'instagram' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/instagram" }>
                  Instagram
                  { Object.keys( lockedFiles ).includes( 'extract-meta-ig' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>
              </li>
              <li>
                <Link className={window.location.pathname.includes( 'ads' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/ads" }>
                  Ads
                  { Object.keys( lockedFiles ).includes( 'extract-meta-ads' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>
              </li>
              <li>
                <Link className={window.location.pathname.includes( 'matomo' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/matomo" }>
                  Matomo
                  { Object.keys( lockedFiles ).includes( 'extract-matomo' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>
              </li>
              {/* <li>
                <Link className={window.location.pathname.includes( 'linkedin' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/linkedin" }>
                  Linkedin
                  { Object.keys( lockedFiles ).includes( 'extract-linkedin' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>
              </li> */}
              <li>
                <Link className={window.location.pathname.includes( 'google-gsc' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/google-gsc" }>
                  Google GSC
                  { Object.keys( lockedFiles ).includes( 'extract-google-gsc' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>
              </li>
              <li>
                <Link className={window.location.pathname.includes( 'google-psi' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/google-psi" }>
                  Google PSI
                  { Object.keys( lockedFiles ).includes( 'extract-google-psi' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>
              </li>
            </ul>
          </div>
          <div className='categories'>
            <h3>Transform</h3>
            <ul>
              <li>
                <Link className={window.location.pathname.includes( 'transformer' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/transformer" }>
                  Transformation des données
                  { Object.keys( lockedFiles ).includes( 'transform-datas' ) ? <ReactOvalLoader width={15} height={15} /> : null }
                </Link>                
              </li>
            </ul>
          </div>
          <div className='categories'>
            <h3>Load</h3>
            <ul>
            <li>
              <Link className={window.location.pathname.includes( 'loader' ) ? 'on' : ''} to={ process.env.PUBLIC_URL + "/loader" }>
                Export des données
                { Object.keys( lockedFiles ).includes( 'load-datas' ) ? <ReactOvalLoader width={15} height={15} /> : null }
              </Link>              
            </li>
            </ul>
          </div>
        </nav>
      </header>
      <section className='content-wrapper'>
        { cloneElement( page, { lockedFiles: lockedFiles } ) }
      </section>
      <footer><p>© Copyright KAIRN - {new Date().getFullYear()}</p></footer>
    </React.Fragment>
  );
}

export default App;