/** Dependencies */
import React from 'react';

/** CSS */
import './Home.scss';

function Home() 
{
  return (
    <div className='home'>
      <h1>Bienvenue</h1>
    </div>
  )
}
  
export default Home;