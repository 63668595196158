/** Dependencies */
import React, { useEffect, useState } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { setItem, getItem, removeItem } from "./../../services/LocaleStorage";

/** Redux **/
import { useDispatch } from 'react-redux';
import { addConnectedAccounts, removeConnectedAccounts } from './../../reducers/connectedAccounts';

/** Images */
import FBLogo from './../../assets/img/logoFB.png';
import Login from './../../assets/img/login.svg';
import Logout from './../../assets/img/logout.svg';

/** CSS */
import './index.scss';

const FBAuthButton = ( props ) => 
{
  const {
    appId,
    scope
  } = props;

  // define local storage name for token
  const localStorageToken = 'fblst_' + process.env.REACT_APP_META_APP_ID;

  /** Init state **/
  const [fbUserDatas, setFbUserDatas] = useState( JSON.parse( getItem( 'fbUserDatas' ) ) );

  /** Instance dispatch object **/
	const dispatch = useDispatch();

  const logout = () => 
  {
    removeItem( 'fbUserDatas' );
    removeItem( localStorageToken );
    setFbUserDatas( null );
    dispatch( removeConnectedAccounts( 'meta' ) );
  }

  // reset user datas if token in local storage null
  useEffect( () => 
  {
    if( getItem( localStorageToken ) !== null )
      dispatch( addConnectedAccounts( 'meta' ) );
            
  }, []); 

  return (
    <React.Fragment>
      {/* Login */}
      { fbUserDatas !== null ?
        <div className='content'>
          <img 
            src={ FBLogo }
            width="50"
            alt='Face Book logo'
          />
          <img 
            src={ fbUserDatas.picture.data.url } 
            width={ fbUserDatas.picture.data.width } 
            title={ fbUserDatas.name + ' - ' + fbUserDatas.email }
            alt='Face Book user'
          />
          <img onClick={() => logout()} src={Logout} width="50" className='logout' alt='logout button' />
        </div>
      : 
        <FacebookLogin
          appId = {appId}
          scope = {scope}
          onSuccess = { response => {
            console.log( response );  
          }}
          onFail = { error => {
            console.log( error );
          }}
          onProfileSuccess = { response => {
            setFbUserDatas( response );
            setItem( 'fbUserDatas', JSON.stringify( response ) );
            dispatch( addConnectedAccounts( 'meta' ) );
          }}
          render={({ onClick }) => (
            <div className='content'>
              <img 
                src={ FBLogo }
                width="50"
                alt="Face Book logo"
              />
              <img onClick={() => onClick()} src={Login} width="50" className='login' alt='login button' />
            </div>
          )}
        />
      }
    </React.Fragment>
  )
}

export default FBAuthButton;