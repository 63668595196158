/** Dependencies */
import React, { useState, useEffect } from 'react';
import { getItem } from "./../services/LocaleStorage";

/** Redux **/
import { useSelector } from 'react-redux';

/** Components */
import { ReactComponent as ReactLoader }  from './../assets/img/loader-dots.svg';
import { ReactComponent as Bin }  from './../assets/img/bin.svg';

/** CSS */
import './Loader.scss';

function Loader( props ) 
{
  const {
    lockedFiles
  } = props;

  /** Init state **/
  const [isSpreadsheetLoading, setIsSpreadsheetLoading] = useState( false );
  const [isLoaderLoading, setIsLoaderLoading] = useState( false );
  const [selectedCookedFiles, setSelectedCookedFiles] = useState( {} );
  const [spreadsheetID, setSpreadsheetID] = useState( '' );
  const [sheetsNames, setSheetsNames] = useState( [] );
  const [cookedFiles, setCookedFiles] = useState( [] );
  const [error, setError] = useState( null );

  /** Get state from redux store **/
  const connectedAccounts = useSelector( state => state.connectedAccounts );

  // defined if transformer is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'load-datas' );

  /** Execute sending datas to gsheet */
  const executeLoad = () => 
  {
    if( 
      spreadsheetID.trim() !== '' 
      && Object.keys( selectedCookedFiles ).length > 0
    ){
      // set loading to true 
      setIsLoaderLoading( true );      

      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          userToken: getItem( 'googleAccessToken' ),
          spreadsheetID: spreadsheetID,
          selectedCookedFiles: selectedCookedFiles
        })
      };

      fetch( process.env.REACT_APP_API_URL + '/load-datas', requestOptions)
        .then (response => response )
        .then( data => {
          setIsLoaderLoading( false );
          setSelectedCookedFiles( {} );
        });   
    }
  }

  /** Update selected cooked list */
  const updateSelectedCookedList = ( event, sheetName ) => 
  {
    // get page id
    const cookedFile = event.target.value;

    // get checked
    const checked = event.target.checked;

    // add sheetname index if not exist
    if( selectedCookedFiles[sheetName] === undefined )
      selectedCookedFiles[sheetName] = [];

    // selected cooked file
    if( checked === false )
    {
      selectedCookedFiles[sheetName] = selectedCookedFiles[sheetName].filter( value => value !== cookedFile );

      if( selectedCookedFiles[sheetName].length === 0 )
        delete selectedCookedFiles[sheetName];

    // unselected cooked file
    } else
      selectedCookedFiles[sheetName] = [ ...selectedCookedFiles[sheetName], cookedFile ];

    setSelectedCookedFiles( {...selectedCookedFiles} );
  }

  /** Spreadsheet loading */
  const loadSpreadsheet = () => 
  {
    if( spreadsheetID.trim() !== '' )
    {
      setIsSpreadsheetLoading( true );    
      setError( null )

      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          userToken: getItem( 'googleAccessToken' ),
          spreadsheetID: spreadsheetID
        })
      };

      fetch( process.env.REACT_APP_API_URL + '/get-sheetsNames', requestOptions)
      .then (response => response.json() )
      .then( data => 
      {
        if( data?.error !== undefined )
        {
          // set error message
          setError( JSON.parse( data.error ).error );

        } else {

          // reset error message
          setError( null );
          
          // set sheets names from request
          setSheetsNames(
            data.filter( elem => 
              ['FB', 'ADS', 'IG', 'WEB'].includes( elem ) 
              || ['FB', 'ADS', 'IG', 'WEB'].includes( elem.substring( 0, ( elem.indexOf( '_' ) ) ) )
            )
          );
        }
        
        setIsSpreadsheetLoading( false );
      });
    }
  }

  /** Remove cooked file */
  const removeItem = ( sheetName, fileName ) => 
  {
    if( window.confirm( 'Étes vous sûr de vouloir supprimer cette donnée ?' ) )
    {
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          sheetName: sheetName,
          filesName: fileName
        })
      };

      fetch( process.env.REACT_APP_API_URL + '/remove-cooked-datas', requestOptions )
      .then (response => response.json() )
      .then( data => {
        setCookedFiles( {...cookedFiles, [sheetName]: cookedFiles[data.sheetName].filter( fileName => fileName !== data.filesName ) } )
      });
    }
  }

  /** cooked file loading */
  useEffect( () => 
  {
    /** Load list of cooked datas */
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        sheetName: ''
      })
    };

    fetch( process.env.REACT_APP_API_URL + '/list-cooked-files', requestOptions )
    .then (response => response.json() )
    .then( data => {
      setCookedFiles( data );
    });

  }, []);

  return (
    <div className="loader">

      { connectedAccounts.value.includes( 'google' ) ?
          !isLocked ?
            <React.Fragment>
              <div className='gsheet-identifier'>
                <label>Indentifiant du Google Sheet : </label>
                <input onChange={ evt => setSpreadsheetID( evt.target.value ) } type="text" value={spreadsheetID} />
                <div className='execution'>
                  <button 
                    disabled={isSpreadsheetLoading}
                    onClick={loadSpreadsheet}
                  >Charger le fichier de destination</button>
                  {isSpreadsheetLoading ? <ReactLoader /> : false }
                </div>
              </div>

              { error !== null ?
                <div className='error'>{error.code} - {error.message} - {error.status}</div>
                : null
              }

              <div className='sheets-list'>
                {(
                  sheetsNames.length > 0 
                  && Object.keys( cookedFiles ).length > 0
                ) ?
                  sheetsNames.map( ( sheetName, index ) => 
                    <div key={index}>
                      <h2>{sheetName}</h2>
                      <ul>
                        {Object.keys( cookedFiles ).filter( sourcesNames => sheetName.includes( sourcesNames ) ).length > 0 ? 
                          Object.keys( cookedFiles ).filter( sourcesNames => sheetName.includes( sourcesNames ) ).map( sourceName => 
                            cookedFiles[sourceName].map( ( cookedFile, index ) => 
                              <li key={index}>
                                <input onChange={ event => updateSelectedCookedList( event, sheetName ) } type='checkbox' value={cookedFile} id={sheetName + '-' + cookedFile} />
                                <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( sourceName, cookedFile ) } />
                                <label title={cookedFile} htmlFor={sheetName + '-' + cookedFile}>{cookedFile}</label>
                              </li>
                            )
                          )
                          : <li>Pas de donnée disponible</li>
                        }
                      </ul>
                    </div>
                  )
                  : null
                }
              </div>

              <div className='data-loader'>
                <div className='execution'>
                  <button 
                    disabled={
                      ( 
                        sheetsNames.length === 0 
                        || Object.keys( selectedCookedFiles ).length === 0 
                        || isLoaderLoading 
                      )
                    } 
                    onClick={executeLoad}
                  >Exporter les données</button>
                  {isLoaderLoading ? <ReactLoader /> : null }
                </div>
              </div>
            </React.Fragment>
            : 
            <div>
              Chargement en cours dans le Gsheet d'ID : {lockedFiles['load-datas'].spreadsheetID}
              <br />
              { 
                Object.keys( lockedFiles['load-datas'].selectedCookedFiles ).map( ( sourceTab, index ) => 
                  <p key={index}>
                    <b>{sourceTab} : </b>
                    { Object.values( lockedFiles['load-datas'].selectedCookedFiles[sourceTab] ).join(' / ') }
                  </p>
                )
              }            
            </div>
        : <p>Vous devez vous connecter à Google</p>
      }
    </div>
  );
}

export default Loader; 