/** Dependencies */
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

/** Redux */
import { Provider } from 'react-redux'
import store from './store'

/** Routes and Contents **/
import App from './components/App';
import Home from './pages/Home';
import FaceBookPage from './pages/FaceBook';
import InstagramPage from './pages/Instagram';
import AdsPage from './pages/Ads';
import MatomoPage from './pages/Matomo';
import LinkedinPage from './pages/Linkedin';
import GoogleGSCPage from './pages/GoogleGSC';
import GooglePSIPage from './pages/GooglePSI';
import TransformerPage from './pages/Transformer';
import LoaderPage from './pages/Loader';

const router = createBrowserRouter([
  {
    path: process.env.PUBLIC_URL,
    element: <App page={<Home />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/facebook",
    element: <App page={<FaceBookPage metaSource='fb' />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/instagram",
    element: <App page={<InstagramPage metaSource='ig' />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/ads",
    element: <App page={<AdsPage metaSource='ads' />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/matomo",
    element: <App page={<MatomoPage />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/linkedin",
    element: <App page={<LinkedinPage />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/google-gsc",
    element: <App page={<GoogleGSCPage />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/google-psi",
    element: <App page={<GooglePSIPage />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/transformer",
    element: <App page={<TransformerPage />} />,
  },
  {
    path: process.env.PUBLIC_URL + "/loader",
    element: <App page={<LoaderPage />} />,
  }
]);

createRoot(document.getElementById("root")).render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </GoogleOAuthProvider>
);