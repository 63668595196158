/** Dependencies */
import React, { useState, useEffect } from 'react';
import { getItem } from '../services/LocaleStorage';

/** Components */
import Logger from './../components/Logger';
import { ReactComponent as ReactLoader }  from './../assets/img/loader-dots.svg';

/** Redux **/
import { useSelector } from 'react-redux';

/** CSS */
import './FaceBook.scss';

function Ads( props ) 
{
  const {
    metaSource,
    lockedFiles
  } = props;

  // define local storage name for token
  const localStorageToken = 'fblst_' + process.env.REACT_APP_META_APP_ID;
  const fbAccessToken = getItem( localStorageToken );

  /** Init state **/
  const [loginDatas, setLoginDatas] = useState( null );
  const [isLoading, setIsLoading] = useState( false );
  const [startDate, setStartDate] = useState( null );
  const [endDate, setEndDate] = useState( null );
  const [adsAccountID, setAdsAccountID] = useState( null );
  const [customerName, setCustomerName] = useState( null );
  
  /** Get state from redux store **/
  const connectedAccounts = useSelector( state => state.connectedAccounts );

  // defined if extractor is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'extract-meta-ads' );

  /** Execute extract ADS */
  const executeExtract = () => 
  {
    // set loading to true 
    setIsLoading( true );

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        userID: loginDatas.id,
        userToken: fbAccessToken,
        adsAccountID: adsAccountID,
        customerName: customerName,
        startDate: startDate,
        endDate: endDate
      })
    };

    fetch( process.env.REACT_APP_API_URL + '/extract-' + metaSource + 'pageinsights', requestOptions)
    .then (response => response )
    .then( data => {
      setIsLoading( false );
      setAdsAccountID( null );
      setStartDate( null );
      setEndDate( null );
    });
  }

  // init login datas
  useEffect( () => setLoginDatas( JSON.parse( getItem( 'fbUserDatas' ) ) ), [] );

  return (
    <div className="facebook ads">

      <h2>Meta Ads</h2>

      { connectedAccounts.value.includes( 'meta' ) && !isLocked ?
        <React.Fragment>
          {/* Fields */}
          <div className="extract-fields">
            <form>
              <div>
                <label>Date de début : </label><input onChange={ e => setStartDate( e.target.value )} type="date" name="start-date" />
              </div>
              <div>
                <label>Date de fin : </label><input onChange={ e => setEndDate( e.target.value )} type="date" name="end-date" />
              </div>
              <div>
                <label>Account ID : </label><input onChange={ e => setAdsAccountID( e.target.value )} type="text" name="account_id" />
              </div>
              <div>
                <label>Nom du client : </label><input onChange={ e => setCustomerName( e.target.value )} type="text" name="customer_name" />
              </div>
            </form>
          </div>

          {/* Execute */}
          <div className='execution'>
            <button
              disabled={
                loginDatas === null
                || isLoading
                || startDate === null
                || startDate.trim() === ''
                || endDate === null
                || endDate.trim() === ''
                || adsAccountID === null
                || adsAccountID.trim() === ''
                || customerName === null
                || customerName.trim() === ''
              }
              onClick={executeExtract}
            >Exécuter l'extraction des données</button>
            {isLoading ? <ReactLoader /> : false }
          </div>
        </React.Fragment>
        : isLocked ? 
            <p>
              Extraction en cours pour le client : { lockedFiles['extract-meta-ads'].customerName }
              <br />
              {lockedFiles['extract-meta-ads'].startDate !== null ? 
                'Du ' 
                + lockedFiles['extract-meta-ads'].startDate.split( '-' )[2] + '/' 
                + lockedFiles['extract-meta-ads'].startDate.split( '-' )[1] + '/' 
                + lockedFiles['extract-meta-ads'].startDate.split( '-' )[0]
                : ''
              }
              <br />
              {lockedFiles['extract-meta-ads'].endDate !== null ? 
                'Au ' 
                + lockedFiles['extract-meta-ads'].endDate.split( '-' )[2] + '/' 
                + lockedFiles['extract-meta-ads'].endDate.split( '-' )[1] + '/' 
                + lockedFiles['extract-meta-ads'].endDate.split( '-' )[0]
                : ''
              }
            </p>
          : <p>Vous devez vous connecter à Facebook</p>
      }

      {/* Log */}
      <Logger serviceName={ 'meta-' + metaSource + '-posts' } />
      
    </div>
  )
}

export default Ads; 