/** Dependencies */
import React from 'react';

/** Components */
import FileUploader from '../components/FileUploader';
import Logger from './../components/Logger';

/** CSS */
import './Linkedin.scss';

function Linkedin( props ) 
{
  const {
    lockedFiles
  } = props;

  // defined if extractor is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'extract-linkedin' );

  return (
    <div className='linkedin'>
      <h2>Linkedin</h2>

      {/* File Uploader */}
      {!isLocked ? 
        <FileUploader fetchURL={process.env.REACT_APP_API_URL + '/extract-linkedininsights'} />
        : <p>Extraction en cours du fichier : { lockedFiles['extract-linkedin'].fileName }</p>
      }

      {/* Log */}
      <Logger serviceName={ 'linkedin' } />

    </div>
  );
}

export default Linkedin; 