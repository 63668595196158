/** Dependencies */
import React, { useEffect, useState } from 'react';

/** Components */
import { ReactComponent as ReactLoader }  from './../assets/img/loader-dots.svg';
import { ReactComponent as Bin }  from './../assets/img/bin.svg';

/** Helpers */
import { orderObjectKeysWithPattern } from './../helpers/functions';

/** CSS */
import './Transformer.scss';

function Transformer( props )
{
  const {
    lockedFiles
  } = props;

  /** Init States */
  const [isLoading, setIsLoading] = useState( false );
  const [selectedSources, setSelectedSources] = useState( {} );
  const [rawFiles, setRawFiles] = useState( [] );

  // defined if transformer is locked
  const isLocked = Object.keys( lockedFiles ).includes( 'transform-datas' );

  /** Execute Transform with selected sources */
  const executeTransform = () => 
  {
    // check if google psi is selected in WEB case
    if( 
      selectedSources['WEB'] !== undefined 
      && selectedSources['WEB']['google-psi'] === undefined
    ){
      alert( 'Vous devez choisir une source Google PSI afin de transformer les données WEB.')
    } else {

      if( Object.keys( selectedSources ).length > 0 )
      {
        // set loading to true 
        setIsLoading( true );
  
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            selectedSources: selectedSources
          })
        };
  
        fetch( process.env.REACT_APP_API_URL + '/transform-datas', requestOptions)
        .then (response => response )
        .then( data => {
          setIsLoading( false );
          setSelectedSources( {} );
        });   
      }    
    }
  }

  /** Update selected page list */
  const updateSelectedPageList = ( event, sheetName, source ) => 
  {
    // get page id
    const pageID = event.target.value;

    // get checked
    const checked = event.target.checked;

    // add sheetname index if not exist
    if( selectedSources[sheetName] === undefined )
      selectedSources[sheetName] = {};

    // add source index if not exist
    if( selectedSources[sheetName][source] === undefined )
      selectedSources[sheetName][source] = [];

      // remove selected source
    if( checked === false )
    {
      selectedSources[sheetName][source] = selectedSources[sheetName][source].filter( value => value !== pageID );

      if( selectedSources[sheetName][source].length === 0 )
        delete selectedSources[sheetName][source];

      if( Object.keys( selectedSources[sheetName] ).length === 0 )
        delete selectedSources[sheetName];

    // add selected source
    } else
      selectedSources[sheetName][source] = [ ...selectedSources[sheetName][source], pageID ];

    // order WEB sheet
    if( selectedSources['WEB'] )
      selectedSources['WEB'] = orderObjectKeysWithPattern( selectedSources['WEB'], ['matomo', 'google-gsc', 'google-psi'] );
    
    setSelectedSources( {...selectedSources} );
  }

  /** Remove selected extracts files */
  const removeItem = ( serviceName, fileName ) => 
  {
    if( window.confirm( 'Étes vous sûr de vouloir supprimer cette donnée ?' ) )
    {
      // Simple POST request with a JSON body using fetch
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          serviceName: serviceName,
          filesName: fileName
        })
      };

      fetch( process.env.REACT_APP_API_URL + '/remove-raw-datas', requestOptions )
      .then (response => response.json() )
      .then( data => {
        setRawFiles( {...rawFiles, [serviceName]: rawFiles[data.serviceName].filter( fileName => fileName !== data.filesName ) } )
      });
    }
  }

  /** Load raw files */
  useEffect( () => 
  {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        serviceName: ''
      })
    };

    fetch( process.env.REACT_APP_API_URL + '/list-raw-files', requestOptions )
      .then (response => response.json() )
      .then( data => {
        setRawFiles( data );
      });

  }, []);

  return(
    <div className="transformer">
      <div className="extract-sources-wrapper">
        { !isLocked ?
          <React.Fragment>
            <div>
              <h2>Données Meta Facebook</h2>
              <ul>
                {rawFiles['meta-fb'] ?
                  rawFiles['meta-fb'].map( ( value, index ) =>
                    <li key={index}>
                      <input onChange={ event => updateSelectedPageList( event, 'FB', 'meta-fb' ) } type='checkbox' id={'meta-fb-' + value} value={value} />
                      <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( 'meta-fb', value ) } />
                      <label htmlFor={'meta-fb-' + value}>{value}</label>
                    </li>
                  )
                  : <li>Pas de donnée disponible</li>
                }
              </ul>
            </div>
            <div>
              <h2>Données Meta Instagram</h2>
              <ul>
                {rawFiles['meta-ig'] ?
                  rawFiles['meta-ig'].map( ( value, index ) =>
                    <li key={index}>
                      <input onChange={ event => updateSelectedPageList( event, 'IG', 'meta-ig' ) } type='checkbox' id={'meta-ig-' + value} value={value} />
                      <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( 'meta-ig', value ) } />
                      <label htmlFor={'meta-ig-' + value}>{value}</label>
                    </li>
                  )
                  : <li>Pas de donnée disponible</li>
                }
              </ul>
            </div>
            <div>
              <h2>Données Meta Ads</h2>
              <ul>
                {rawFiles['meta-ads'] ?
                  rawFiles['meta-ads'].map( ( value, index ) =>
                    <li key={index}>
                      <input onChange={ event => updateSelectedPageList( event, 'ADS', 'meta-ads' ) } type='checkbox' id={'meta-ads-' + value} value={value} />
                      <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( 'meta-ads', value ) } />
                      <label htmlFor={'meta-ads-' + value}>{value}</label>
                    </li>
                  )
                  : <li>Pas de donnée disponible</li>
                }
              </ul>
            </div>
            <div>
              <h2>Données Web</h2>
              <h3>Données Matomo</h3>
              <ul>
                {rawFiles['matomo'] ?
                  rawFiles['matomo'].map( ( value, index ) =>
                    <li key={index}>
                      <input onChange={ event => updateSelectedPageList( event, 'WEB', 'matomo' ) } type='checkbox' id={'matomo-' + value} value={value} />
                      <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( 'matomo', value ) } />
                      <label htmlFor={'matomo-' + value}>{value}</label>
                    </li>
                  )
                  : <li>Pas de donnée disponible</li>
                }
              </ul>
              <h3>Données Google GSC</h3>
              <ul>
                {rawFiles['google-gsc'] ?
                  rawFiles['google-gsc'].map( ( value, index ) =>
                    <li key={index}>
                      <input onChange={ event => updateSelectedPageList( event, 'WEB', 'google-gsc' ) } type='checkbox' id={'google-gsc-' + value} value={value} />
                      <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( 'google-gsc', value ) } />
                      <label htmlFor={'google-gsc-' + value}>{value}</label>
                    </li>
                  )
                  : <li>Pas de donnée disponible</li>
                }
              </ul>
              <h3>Données Google PSI</h3>
              <ul>
                {rawFiles['google-psi'] ?
                  rawFiles['google-psi'].map( ( value, index ) =>
                    <li key={index}>
                      <input onChange={ event => updateSelectedPageList( event, 'WEB', 'google-psi' ) } type='checkbox' id={'google-psi-' + value} value={value} />
                      <Bin width="15" height="15" className='bin'  onClick={ () => removeItem( 'google-psi', value ) } />
                      <label htmlFor={'google-psi-' + value}>{value}</label>
                    </li>
                  )
                  : <li>Pas de donnée disponible</li>
                }   
              </ul>     
            </div>
          </React.Fragment>
          : 
          <div>
            Transformation en cours : 
            <br />
            { 
              Object.keys( lockedFiles['transform-datas'].selectedSources ).map( ( sourceTab, index ) => 
                <p key={index}>
                  <b>{sourceTab} : </b>
                  { Object.values( lockedFiles['transform-datas'].selectedSources[sourceTab] ).join(' / ') }
                </p>
              )
            }            
          </div>
        }
      </div>   
      { !isLocked ?   
        <div className='execution'>
          <button 
            disabled={ 
              Object.keys( selectedSources ).length === 0 
              || isLoading
            } 
            onClick={ () => executeTransform() }
          >Transformer les données</button>      
          {isLoading ? <ReactLoader /> : null }
        </div>
        : null
      }
    </div>
  )
}

export default Transformer; 